import { Layout } from "./components/Layout/Layout";
import { IssueView } from "./components/IssueView/IssueView";
import { IssueComments } from "./components/IssueComments/IssueComments";
import {
  createBrowserRouter,
  RouterProvider,
  LoaderFunctionArgs,
  useLoaderData,
} from "react-router-dom";
import "@atlaskit/css-reset";
import { IssueDetails } from "./components/IssueDetails/IssueDetails";
import { AuthProvider } from "./AuthProvider";

declare global {
  interface Window {
    issueData: string;
  }
}

const router = createBrowserRouter([
  {
    id: "root",
    path: "/issues/:urlId",
    loader: async ({ params }: LoaderFunctionArgs) => {
      const response = await fetch(
        `${process.env.API_BASE}/issues/urls/${params.urlId}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch issue");
      }

      return response.json();
    },
    Component: () => {
      const { issue, id: issueId, mediaToAttachmentMap } = useLoaderData();

      return (
        <Layout
          issue={issue}
          sidebar={
            <>
              <IssueDetails issue={issue} />
              <IssueComments issueId={issueId} />
            </>
          }
        >
          <IssueView
            issueId={issueId}
            issue={issue}
            mediaToAttachmentMap={mediaToAttachmentMap}
          />
        </Layout>
      );
    },
  },
]);

const App = () => {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;
