const encodings = {
  "+": "%2B",
  "!": "%21",
  '"': "%22",
  "#": "%23",
  $: "%24",
  "&": "%26",
  "'": "%27",
  "(": "%28",
  ")": "%29",
  "*": "%2A",
  ",": "%2C",
  ":": "%3A",
  ";": "%3B",
  "=": "%3D",
  "?": "%3F",
  "@": "%40",
};

export const encodeS3URI = (filename: string): string => {
  return encodeURI(filename) // Do the standard url encoding
    .replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/gim, (match) => {
      // @ts-expect-error
      return encodings[match];
    });
};
