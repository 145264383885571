import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG!);

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const getToken = async () => auth.currentUser?.getIdToken();

if (auth.isSignInWithEmailLink(window.location.href)) {
  const email = window.localStorage.getItem("emailForSignIn");
  const displayName = window.localStorage.getItem("displayName");
  if (email && displayName) {
    auth.signInWithEmailLink(email, window.location.href).then(() => {
      auth.currentUser
        ?.updateProfile({ displayName })
        .then(() => {
          window.location.href = window.location.href.split("?")[0];
        })
        .catch(console.error);
      window.localStorage.removeItem("emailForSignIn");
      window.localStorage.removeItem("displayName");
    });
  }
}
