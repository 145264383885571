import { useMemo } from "react";
import { Field, FormSection } from "@atlaskit/form";
import { Box } from "@atlaskit/primitives";
import { ReactRenderer } from "@atlaskit/renderer";
import { JiraIssueData } from "../../../../common_types/jira";
import { createMediaComponent } from "../IssueMediaComponent/IssueMediaComponent";

export const IssueView = ({
  issueId,
  issue,
  mediaToAttachmentMap,
}: {
  issueId: string;
  issue: JiraIssueData;
  mediaToAttachmentMap: Record<string, string>;
}) => {
  const attachmentMap = useMemo(() => {
    return Object.entries(mediaToAttachmentMap)
      .map(([mediaId, attachmentId]) => {
        const attachment = issue.fields.attachment.find(
          (attachment) => String(attachment.id) === attachmentId
        );

        if (attachment) {
          return {
            [mediaId]: attachment,
          };
        }

        return {};
      })
      .reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
  }, [mediaToAttachmentMap, issue.fields.attachment]);
  const MediaComponent = useMemo(
    () => createMediaComponent(attachmentMap, issueId),
    [attachmentMap, issueId]
  );

  return (
    <Box>
      <FormSection>
        <Field name="description" label="Description">
          {() => (
            <ReactRenderer
              document={issue.fields.description}
              nodeComponents={{
                media: MediaComponent,
              }}
            />
          )}
        </Field>
      </FormSection>
    </Box>
  );
};
