import { JiraAttachment } from "../../../../common_types/jira";
import { encodeS3URI } from "./encodeS3uri";

const getMediaSrc = (attachment: JiraAttachment, issueId: string): string => {
  return `${process.env.STATIC_BASE}/issue/${issueId}/thumbnail/${
    attachment.id
  }/${encodeS3URI(attachment.filename)}`;
};

export const createMediaComponent =
  (attachmentMap: Record<string, JiraAttachment>, issueId: string) =>
  (props: {
    id: string;
    width: number;
    height: number;
    cardDimensions: { width: number; height: number };
  }) => {
    const { id: mediaId, width, height, cardDimensions } = props;
    const attachment = attachmentMap[mediaId];

    return (
      <img
        style={{
          objectFit: "contain",
          ...(width && height
            ? {
                position: "absolute",
                top: 0,
                left: 0,
                imageOrientation: "none",
                width,
                height,
                maxWidth: "100%",
                maxHeight: "100%",
              }
            : cardDimensions),
        }}
        src={getMediaSrc(attachment, issueId)}
      />
    );
  };
