import firebase from "firebase/compat/app";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./auth";

const UserContext = createContext<firebase.User | null>(null);

export const useCurrentUser = (): firebase.User | null => {
  return useContext(UserContext);
};

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [currentUser, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
    });
  }, []);

  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
};
