import { Field, FormSection } from "@atlaskit/form";
import { Box, xcss } from "@atlaskit/primitives";
import Lozenge from "@atlaskit/lozenge";
import Avatar from "@atlaskit/avatar";
import Comment, { CommentAuthor } from "@atlaskit/comment";
import { SimpleTag } from "@atlaskit/tag";
import TagGroup from "@atlaskit/tag-group";
import Heading from "@atlaskit/heading";
import { formatDistance } from "date-fns";
import { JiraIssueData } from "../../../../common_types/jira";

const getStatusAppearance = (statusCategory: string) => {
  switch (statusCategory) {
    case "To Do":
      return "new";
    case "In Progress":
      return "inprogress";
    case "Done":
      return "success";
    default:
      return "default";
  }
};

const boxStyles = xcss({
  borderColor: "color.border",
  borderStyle: "solid",
  borderRadius: "border.radius",
  borderWidth: "border.width",
});

export const IssueDetails = ({ issue }: { issue: JiraIssueData }) => {
  return (
    <Box xcss={boxStyles} padding="space.100">
      <Heading level="h400">Details</Heading>
      <FormSection>
        <Field name="status" label="Status">
          {() => (
            <div>
              <Lozenge
                appearance={getStatusAppearance(
                  issue.fields.status.statusCategory.name
                )}
              >
                {issue.fields.status.name}
              </Lozenge>
            </div>
          )}
        </Field>
      </FormSection>
      {issue.fields.assignee && (
        <FormSection>
          <Field name="assignee" label="Assignee">
            {() => (
              <Comment
                avatar={
                  <Avatar src={issue.fields.assignee.avatarUrls["48x48"]} />
                }
                author={
                  <CommentAuthor>
                    {issue.fields.assignee.displayName}
                  </CommentAuthor>
                }
              />
            )}
          </Field>
        </FormSection>
      )}

      {issue.fields.reporter && (
        <FormSection>
          <Field name="reporter" label="Reporter">
            {() => (
              <Comment
                avatar={
                  <Avatar src={issue.fields.reporter.avatarUrls["48x48"]} />
                }
                author={
                  <CommentAuthor>
                    {issue.fields.reporter.displayName}
                  </CommentAuthor>
                }
              />
            )}
          </Field>
        </FormSection>
      )}

      {issue.fields.labels.length > 0 && (
        <FormSection>
          <Field name="labels" label="Labels">
            {() => (
              <TagGroup>
                {issue.fields.labels.map((label) => (
                  <SimpleTag text={label} />
                ))}
              </TagGroup>
            )}
          </Field>
        </FormSection>
      )}
      <FormSection>
        <Field name="created" label="Created">
          {() => (
            <span>
              {" "}
              {formatDistance(new Date(issue.fields.created), new Date())} ago
            </span>
          )}
        </Field>
      </FormSection>
      <FormSection>
        <Field name="updated" label="Updated">
          {() => (
            <span>
              {" "}
              {formatDistance(new Date(issue.fields.updated), new Date())} ago
            </span>
          )}
        </Field>
      </FormSection>
    </Box>
  );
};
