import React from "react";
import { Content, Main, PageLayout, RightPanel } from "@atlaskit/page-layout";
import { JiraIssueData } from "../../../../common_types/jira";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import PageHeader from "@atlaskit/page-header";
import { Box } from "@atlaskit/primitives";
import { FixedFooter } from "./FixedFooter/FixedFooter";
// import { TopNavigation } from "./TopNavigation";

export const Layout: React.FC<{
  issue: JiraIssueData;
  children: React.ReactNode;
  sidebar: React.ReactNode;
}> = ({ issue, sidebar, children }) => {
  return (
    <>
      <div className="layout-container">
        <PageLayout>
          <Content>
            <Main>
              <div style={{ padding: "5px" }}>
                <PageHeader
                  breadcrumbs={
                    <Breadcrumbs>
                      <BreadcrumbsItem
                        iconBefore={
                          <img
                            src={issue.fields.issuetype.iconUrl}
                            style={{ marginRight: "5px" }}
                          />
                        }
                        text={issue.key}
                        key={issue.key}
                      />
                    </Breadcrumbs>
                  }
                >
                  {issue.fields.summary}
                </PageHeader>
              </div>
              <Box padding="space.100">{children}</Box>
            </Main>
          </Content>
          <RightPanel>
            <Box padding="space.100">{sidebar}</Box>
          </RightPanel>
        </PageLayout>
      </div>
      <FixedFooter />
    </>
  );
};
