import "./FixedFooter.css";

export const FixedFooter = () => {
  return (
    <div className="fixed-footer">
      <a
        tabIndex={-1}
        href="https://public-issues-for-jira.net"
        target="_blank"
      >
        Powered by Public Issues for Jira
      </a>
      <br />
      <a
        tabIndex={-1}
        href="https://tally.so/r/wk6ZbZ"
        target="_blank"
        rel="noopener noreferrer"
      >
        Leave feedback
      </a>
    </div>
  );
};
